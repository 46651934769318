import * as React from "react"

import Seo from "../components/seo"
import { navigate } from "gatsby";
import { useEffect } from "react";
import notFoundImage from '../images/bluetape-404.webp'; 
import './styles.scss';

const NotFoundPage = () => {
  useEffect(() => {
    if (window.location.pathname.includes('/blog/')) {
      navigate('/blog');
    }

    if (window.location.pathname.includes('/newsroom/')) {
      navigate('/newsroom');
    }

    if (window.location.pathname.includes('/podcast/')) {
      navigate('/podcast');
    }

    // navigate("/");
  }, []);

  return (
    <div className={'notFoundPage'}>
      <div>
        <img className={'notFoundImage'} src={notFoundImage}/>
      </div>
      <div className={'notFoundText'}>
        <div className={'notFoundPageTitle'}>
          <strong>Oops!</strong> <span className={'notFoundPageSubTitle'}>Looks like you might have lost your way.</span>
        </div>
        <div>
          <span>Something wrong with our blueprint?</span> <a href={'https://bluetape.com/contact-us'}>Let us know.</a>
        </div>
        <div>
          <span>Looking for our financing options? Head over </span><a href={'https://bluetape.com/what-we-offer/financing'}>here.</a>
        </div>
        <div>
          <span>None of the above? Come on back </span><a href={'https://bluetape.com'}>home.</a>
        </div>
      </div>
    </div>
  );
}

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
